<script>
import { Drawer } from 'ant-design-vue'
import DrawerForm from '@/components/DrawerForm'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '',
    },
    // onClose: {
    //   type: Function,
    //   default: null,
    // },
  },
  methods: {
    onClose() {
      this.$listeners.close && this.$listeners.close()
    },
  },
  render() {
    return (
      <Drawer visible={this.visible} title={this.title} width={this.width} onClose={this.onClose}>
        <DrawerForm data={this.data} form={this.form} onSuccess={this.$listeners.submit} ref="form" />
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            // borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            height: '60px',
            zIndex: 1,
          }}
        >
          <a-button
            onClick={() => {
              this.$listeners.close && this.$listeners.close()
            }}
            style={{ marginRight: '10px' }}
          >
            取消
          </a-button>
          <a-button
            type="primary"
            onClick={() => {
              this.$refs.form.sumbit()
            }}
          >
            确定
          </a-button>
        </div>
      </Drawer>
    )
  },
}
</script>
